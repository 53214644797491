import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/UI/Button/Button';
import { FiltersDrawer } from '@/components/FiltersDrawer/FiltersDrawer';
import {
  CloseIcon,
  FiltersIcon,
  SearchIcon,
} from '@/components/UI/Icons/Icons';
import { QUERY } from '@/constants/common';
import { useQueryParam } from '@/hooks/useQueryParam';
import { useRouter } from 'next/router';
import { Badge } from '@/components/UI/Badge/Badge';
import { ROUTES } from '@/constants/routes';
import { getDataStatAttr, isJestRunning } from '@/utils/helpers';
import { useTheme } from 'styled-components';
import { MIN_QUERY_LENGTH } from '@/components/SearchSuggestions/SearchSuggestions.constants';
import { useSearch } from '@/hooks/useSearch';
import { SearchSuggestions } from '@/components/SearchSuggestions/SearchSuggestions';
import { KeywordsSource } from '@/components/SearchSuggestions/SearchSuggestions.types';
import { useSearchStore } from '@/store/useSearchStore';
import { useIsTopBannerVisible } from '@/hooks/useIsTopBannerVisible';
import * as S from './SearchPanel.styles';

interface SearchPanelProps {
  hasFilters?: boolean;
  hasPaddingTop?: boolean;
  dataStat?: string;
}

export const SearchPanel: React.FC<SearchPanelProps> = ({
  hasFilters = false, // TODO: change to true when business logic on BE will be ready
  hasPaddingTop = true,
  dataStat,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const search = useSearch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [initialQuery] = useQueryParam(`${QUERY.q}[${QUERY.keywords}]`);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [isInteractionHappened, setIsInteractionHappened] = useState(false);
  const searchInputRef = useRef<HTMLDivElement>(null);
  const isTopBannerVisible = useIsTopBannerVisible();
  const [isFocused, setIsFocused] = useState(false);

  const [isSuggestionsOpened] = useSearchStore(state => [
    state.isSuggestionsOpened,
  ]);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setQuery(e.target.value);
    setIsInteractionHappened(e.target.value.length >= MIN_QUERY_LENGTH);
  }

  function onFocus() {
    setIsFocused(true);
    setIsInteractionHappened(!!query);
  }

  function clear() {
    setQuery('');
    inputRef.current?.focus();
  }

  function scrollToInput() {
    inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  function submit(e: React.FormEvent) {
    e.preventDefault();
    search(query, { keywordsSource: KeywordsSource.Typewritten });
  }

  function toggleFilters() {
    setIsFiltersOpen(prev => !prev);
  }

  useEffect(() => {
    const handleStart = (pageUrl: string) => {
      setIsInteractionHappened(false);
      if (pageUrl === ROUTES.home) {
        setQuery('');
      }
    };

    router.events.on('routeChangeStart', handleStart);

    return () => {
      router.events.off('routeChangeStart', handleStart);
    };
  }, []);

  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery);
    }
  }, [initialQuery]);

  const offsetTop = useMemo(() => {
    if (searchInputRef.current) {
      const { height, top } = searchInputRef.current.getBoundingClientRect();
      const MARGIN = 10;
      const suggestionOffset = height + top - MARGIN;

      return suggestionOffset;
    }

    return 0;
  }, [searchInputRef.current, isTopBannerVisible, isFocused]);

  return (
    <S.Box ref={searchInputRef} $hasPaddingTop={hasPaddingTop}>
      <S.Form onSubmit={submit} action="/">
        <S.Input
          {...getDataStatAttr({ dataStat })}
          ref={inputRef}
          type="search"
          autoCorrect="off"
          autoCapitalize="none"
          autoComplete="off"
          spellCheck="false"
          placeholder={t('search.placeholder')}
          value={query}
          onChange={onChange}
          onFocus={onFocus}
          $hasBorderBottom={false}
          onBlur={() => setIsFocused(false)}
        />

        {query && (isSuggestionsOpened || isJestRunning()) && (
          <Button
            onClick={clear}
            appearance="transparent"
            hasPadding={false}
            data-testid="clear-button"
          >
            <CloseIcon width={13} fill={theme.palette.tertiary.labels} />
          </Button>
        )}

        <S.SubmitButton
          onClick={() => search(query)}
          appearance="transparent"
          shape="square"
          size="s"
          type="submit"
        >
          <SearchIcon />
        </S.SubmitButton>
      </S.Form>

      <SearchSuggestions
        query={query}
        setQuery={setQuery}
        isInteractionHappened={isInteractionHappened}
        setIsInteractionHappened={setIsInteractionHappened}
        scrollToInput={scrollToInput}
        offsetTop={offsetTop}
      />

      {hasFilters && (
        <>
          <Badge value={1}>
            <Button onClick={toggleFilters} shape="square" size="s">
              <FiltersIcon />
            </Button>
          </Badge>

          <FiltersDrawer isOpen={isFiltersOpen} close={toggleFilters} />
        </>
      )}
    </S.Box>
  );
};
